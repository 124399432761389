.element-container {
    display: flex;
    justify-content: space-between;
    min-height: 120px;
}

.box {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dataTables_wrapper .dataTables_length {
    float: left;
    margin-top: 10px;
    margin-left: 20px;
}

.dataTables_wrapper {
  width: 100%;
  overflow-x: auto;
}

.nav-link {
    cursor: pointer;
}

.content-container {
    padding: 0 32px;
}

.tabs-container {
    padding: 0;
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .navbar-content {
        width: 1200px;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media (max-width: 1199px) and (min-width: 1000px) {
    .navbar-content {
        width: 1000px;
    }
}

@media (max-width: 999px) {
    .navbar-content {
        width: 999px;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.custom-confirm-button, .custom-cancel-button {
    display: flex; 
    font-weight: normal;
    justify-content: center; 
    align-items: center;
    width: 75px; 
    height: 40px; 
    padding: 0;
    font-size: 16px; 
  }
  
  .custom-confirm-button:focus,
  .custom-cancel-button:focus {
    outline: none; 
    border: 3px solid #91b6de; 
  }  